<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.edit', {name: $tc('message.appointmentNotification', 2)}) }}</h3>
    <calendarBreadcrumbs :link="'dashboardDefaultMessages'" />
    <div :key="$route.params.id + '1'">

      <div class="flix-form-group" v-if="$route.params.id !== 'all'">
        <router-link :to="{name: 'dashboardReminder', params: {id: $route.params.id}}" class="flix-btn flix-btn-default"><flixIcon id="pending-notifications" /> {{ $tc('message.reminder', 2) }}</router-link>
      </div>
      <div class="flix-form-group" v-if="$route.params.id !== 'all'">
        <div class="flix-btn-group">
          <a href="#" @click.prevent="getEdit('commitment')" class="flix-btn flix-btn-default" :class="{'flix-btn-warning': target === 'commitment'}"><flixIcon id="thumbs-up" /> {{ $t('message.commitment') }}</a>
          <a href="#" @click.prevent="getEdit('cancellation')" class="flix-btn flix-btn-default" :class="{'flix-btn-warning': target === 'cancellation'}"><flixIcon id="thumbs-down" /> {{ $t('message.cancellation') }}</a>
          <a href="#" @click.prevent="getEdit('application')" class="flix-btn flix-btn-default" :class="{'flix-btn-warning': target === 'application'}"><flixIcon id="warning-sign" /> {{ $t('message.validation') }}</a>
        </div>
      </div>
    </div>
    <transition name="flixFadeIn">
      <div v-if="data" :key="getKey()">
        <editTxt :data="data" />

        <div v-if="sms" class="flix-form-group">
          <sms :data="sms" />
        </div>
        <div class="flix-form-group">
          <saveBtn :onClick="function(){ onSave() }">
            <template v-slot:text>
              {{ $t('message.save', { name: '' }) }}
            </template>
          </saveBtn>
        </div>
        <transition name="flixFadeIn">
          <div class="flix-form-group" v-if="saved" :key="saved">
            <div class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
          </div>
        </transition>
      </div>
    </transition>

    <div class="flix-form-group" v-if="$route.params.id !== 'all'" :key="$route.params.id">
      <notificationBlacklist :id="$route.params.id" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    editTxt () { return import('@/components/reminder/defaultMessages/editText') },
    sms () { return import('@/components/reminder/defaultMessages/sms') },
    notificationBlacklist () { return import('./notificationBlacklist') }
  },
  props: {},
  mounted () {

  },
  computed: {

  },
  watch: {
    '$route.params.id' () { if (this.target !== false) { this.getEdit(this.target) } }
  },
  data () {
    return {
      variables: this.$getUserVariables(),
      target: false,
      data: false,
      sms: false,
      saved: false
    }
  },
  methods: {
    getKey () {
      return this.target + this.$route.params.id
    },
    onSave () {
      this.$flix_post({
        data: {
          user: this.variables.user.md5_id,
          ID: this.data.to,
          data: [this.data],
          filter: { ID: this.data.ID }
        },
        url: 'reminder/save',
        callback: function (ret) {
          var reminder = this.$store.state.reminder.reminder
          reminder[this.data.to].forEach(function (r, i) {
            if (r.ID === this.data.ID) {
              reminder[this.data.to][i] = this.data
            }
          }.bind(this))
          this.$store.commit('setReminders', reminder)
          this.saved = true

          setTimeout(function () {
            this.saved = false
          }.bind(this), 3000)
        }.bind(this)
      })
    },
    getEdit (target) {
      if (target === this.target) {
        this.target = false
        this.data = false
        this.sms = false
        return false
      }
      this.target = target
      this.data = false
      this.$store.dispatch('getReminder', { ID: this.$route.params.id, callback: function (data) { this.getFilterData(data) }.bind(this) })
    },
    getFilterData (data) {
      data = JSON.parse(JSON.stringify(data))
      this.data = false
      this.sms = false
      data.forEach(function (val) {
        if (val.trigger_target === this.target && val.format === 'email') {
          this.data = val
        } else if (val.trigger_target === this.target) {
          this.sms = val
        }
      }.bind(this))
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
